import { ReactElement, ReactNode, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../components/shared/LoadingSpinner'
import { Card, Checkbox, Col, Row, Select, Skeleton, Switch, Tag } from 'antd'
import { errorHandler, insertSpaceCamelCase, statusColor } from '../../utils/helper'
import { AccountPermissions, Roles, Status, LocalStorageItems } from '../../constants/common'
import { Link, useNavigate } from 'react-router-dom'
import { AdminButton, SiteButtonVariant } from '../components/controls/AdminButton'
import { useAuth0 } from '@auth0/auth0-react'
import { fetchJobs } from '../services/adminJob'
import { Account } from '../../models/manager/account'
import { Job } from '../../models/manager/job'
import { fetchAccounts, fetchUsersByAccountId } from '../services/adminAccount'
import { AccountUser } from '../../models/manager/accountUser'
import { useProfileContext } from '../../contexts/profileContext'
import { fetchUser } from '../../services/accounts'

interface JobStatusCount {
  Key: number
  StatusId: Status
  Status: ReactNode
  Count: number
}

const AdminDashboard = (): ReactElement => {
  const navigate = useNavigate()
  const { getAccessTokenSilently } = useAuth0()
  const { profile, saveProfile } = useProfileContext()

  const [accountSelected, setAccountSelected] = useState('')
  const [userSelected, setUserSelected] = useState('')
  const [accounts, setAccounts] = useState<Account[]>([])
  const [users, setUsers] = useState<AccountUser[]>([])
  const [jobs, setJobs] = useState<Job[]>([])
  const [jobStatuses, setJobStatuses] = useState<JobStatusCount[]>()
  const [IsShowingCancelled, SetIsShowingCancelled] = useState(
    JSON.parse(localStorage.getItem(LocalStorageItems.ShowCancelledStatus) || 'true')
  )

  const [isLoading, setIsLoading] = useState(false)
  const [showIncomplete, setShowIncomplete] = useState<boolean>(false)

  const transformJobsToStatusCount = (jobsData: Job[]): JobStatusCount[] => {
    const statusCounts: Record<number, number> = {}

    jobsData.forEach((job) => {
      const statusId = job.StatusId
      if (statusCounts[statusId]) {
        statusCounts[statusId]++
      } else {
        statusCounts[statusId] = 1
      }
    })

    return Object.entries(statusCounts)
      .filter(([statusId]) => {
        return !(!IsShowingCancelled && parseInt(statusId) === Status.Cancelled)
      })
      .map(([statusId, count]) => {
        const statusKey = parseInt(statusId) as unknown as Status
        const statusEnumValue = Status[statusKey]
        return {
          Key: statusKey,
          StatusId: statusKey,
          Status: (
            <Tag className="text-lg" style={statusColor(statusKey)} key={statusEnumValue}>
              {insertSpaceCamelCase(statusEnumValue)}
            </Tag>
          ),
          Count: count,
        }
      })
  }

  const handleChange = (value: string) => {
    setAccountSelected(value)
  }

  const handleChangeUser = (value: string) => {
    setUserSelected(value)
  }

  const filterPrintJobs = (statusId: number) => {
    navigate(`/admin/job-listing?statusId=${statusId}`)
  }
  const handleImpersonate = () => {
    getAccessTokenSilently().then((token) => {
      fetchUser(userSelected, token).then((user) => {
        saveProfile({
          ...profile,
          IsImpersonating: true,
          AccountId: parseInt(accountSelected),
          FirstName: user.FirstName ?? '',
          LastName: user.LastName ?? '',
          AuthUid: user.AuthUid,
          AccountUid:
            user?.AccountUsers?.find((x) => x.AccountId == parseInt(accountSelected))?.Account?.AccountGuid ?? '',
          Permissions:
            user?.AccountUsers?.find((x) => x.AccountId == parseInt(accountSelected))?.Permissions ??
            AccountPermissions.All,
          AccountName: user?.AccountUsers?.find((x) => x.AccountId == parseInt(accountSelected))?.Account?.Name ?? '',
          Email: user.Email ?? '',
          Role: user?.AccountUsers?.find((x) => x.AccountId == parseInt(accountSelected))?.RoleId ?? Roles.User,
        })
      })
    })

    navigate('/dashboard')
  }

  const onChange = (checked: boolean) => {
    SetIsShowingCancelled(checked)
    localStorage.setItem(LocalStorageItems.ShowCancelledStatus, JSON.stringify(checked))
  }

  const getJobs = (): number => {
    if (IsShowingCancelled) return jobs.length
    return jobs.filter((j) => j.StatusId != Status.Cancelled).length
  }

  useEffect(() => {
    let isSubscribed = true
    setIsLoading(true)
    const handleStorageChange = () => {
      SetIsShowingCancelled(JSON.parse(localStorage.getItem(LocalStorageItems.ShowCancelledStatus) || '') || false)
    }

    window.addEventListener('storage', handleStorageChange)

    getAccessTokenSilently()
      .then((token) => {
        const fetchPromises = [fetchAccounts(token), fetchJobs(token)]

        Promise.all(fetchPromises)
          .then((results) => {
            if (isSubscribed) {
              setAccounts(results[0] as Account[])
              setJobs(results[1] as Job[])
              setJobStatuses(transformJobsToStatusCount(results[1] as Job[]) ?? undefined)
            }
          })
          .catch((error) => {
            if (isSubscribed) {
              errorHandler(error)
            }
          })
          .finally(() => {
            if (isSubscribed) {
              setIsLoading(false)
            }
          })
      })
      .catch((error) => {
        if (isSubscribed) {
          errorHandler(error)
          setIsLoading(false)
        }
      })

    return () => {
      isSubscribed = false
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [])

  useEffect(() => {
    if (accountSelected) {
      getAccessTokenSilently().then((token) => {
        fetchUsersByAccountId(parseInt(accountSelected), token).then(setUsers)
      })
    }
  }, [accountSelected])

  useEffect(() => {
    setJobStatuses(transformJobsToStatusCount(jobs))
  }, [IsShowingCancelled])

  const handleIncompleteViewChange = () => {
    setShowIncomplete(!showIncomplete)
  }

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      <div className="container mx-auto">
        <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-2 py-2">
          <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Admin Dashboard</h1>
          <div className="flex gap-2 items-center">
            <label htmlFor="">Show Cancelled:</label>
            <Switch checked={IsShowingCancelled} className="bg-PrimaryBgWhite-100" onChange={onChange} />
          </div>
        </div>
        <div className="flex-col justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-3 h-full py-2">
          <Row gutter={[24, 24]} className="h-full flex justify-center">
            <Col xs={24} lg={8} className="border border-BorderLightGrey-100 p-6 rounded-md">
              <div className="border border-PrimaryBgWhite-100 bg-gray-100 rounded-md p-4 mx-4">
                <span>Job Status - Current</span>
              </div>
              {!isLoading ? (
                jobStatuses?.map((job) => (
                  <div key={job.Key} className="flex items-center justify-between px-6 py-2 my-2 w-full border-b">
                    <div className="flex items-center gap-2 justify-between  xl:w-1/2">
                      <div className="flex justify-center">{job.Status}</div>
                      <span className="font-bold text-xl">{job.Count}</span>
                    </div>

                    <div className="flex flex-end">
                      <span
                        className="text-credsPrimaryBlue-100 underline cursor-pointer"
                        onClick={() => filterPrintJobs(job.StatusId)}
                      >
                        View
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <Skeleton active className="mt-5" paragraph={{ rows: 6 }} />
              )}
            </Col>
            <Col xs={24} lg={12} className="flex flex-col justify-between gap-4">
              <div className="border border-BorderLightGrey-100 p-6 rounded-md">
                <Row className="h-full flex justify-center items-center" gutter={[24, 24]}>
                  <Col xs={24} sm={8} xl={12}>
                    {!isLoading ? (
                      <Card
                        className="cardBorder"
                        title="Accounts"
                        extra={
                          <Link
                            to="/admin/account-listing"
                            className="text-credsPrimaryBlue-100 hover:text-PrimaryDarkBlue-100"
                          >
                            View
                          </Link>
                        }
                        headStyle={{ backgroundColor: '#EDEDED' }}
                      >
                        <p className="text-4xl font-semibold">{accounts.length}</p>
                      </Card>
                    ) : (
                      <Skeleton active />
                    )}
                  </Col>
                  <Col xs={24} sm={8} lg={12}>
                    {!isLoading ? (
                      <Card
                        className="cardBorder"
                        title="Jobs"
                        extra={
                          <Link
                            to="/admin/job-listing"
                            className="text-credsPrimaryBlue-100 hover:text-PrimaryDarkBlue-100"
                          >
                            View
                          </Link>
                        }
                        headStyle={{ backgroundColor: '#EDEDED' }}
                      >
                        <p className="text-4xl font-semibold">{getJobs()}</p>
                      </Card>
                    ) : (
                      <Skeleton active />
                    )}
                  </Col>
                </Row>
              </div>
              <div className="border border-BorderLightGrey-100 p-6 rounded-md">
                <Row className="h-full flex justify-center items-center" gutter={[24, 24]}>
                  <Col xs={24}>
                    <Card className="cardBorder" title="Impersonate Account" headStyle={{ backgroundColor: '#EDEDED' }}>
                      <div className="flex flex-col gap-1 w-full items-end">
                        <Checkbox checked={showIncomplete} onChange={() => handleIncompleteViewChange()}>
                          Include Incomplete
                        </Checkbox>
                      </div>
                      <div className="flex flex-col gap-1">
                        <label htmlFor="">Account</label>
                        <Select
                          onChange={handleChange}
                          showSearch
                          className="w-full"
                          placeholder="Search the account"
                          optionFilterProp="children"
                          filterOption={(input, option) => (option?.label ?? '').includes(input)}
                          filterSort={(optionA, optionB) =>
                            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                          }
                          options={accounts
                            .filter((account) => (showIncomplete ? true : account.Name.length > 0))
                            .map((account) => ({
                              value: account.Id,
                              label: account.Name,
                            }))}
                        />
                      </div>
                      <div className="flex flex-col gap-1 mt-2">
                        {accountSelected && (
                          <>
                            <label htmlFor="">User</label>
                            <Select
                              onChange={handleChangeUser}
                              showSearch
                              className="w-full"
                              placeholder="Search the user"
                              optionFilterProp="children"
                              filterOption={(input, option) => (option?.label ?? '').includes(input)}
                              filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                              }
                              options={users.map((user) => ({
                                value: user.User?.AuthUid ?? '',
                                label: `${user.User?.FirstName} ${user.User?.LastName}`,
                              }))}
                            />
                            <div className="flex justify-end mt-3 items-center">
                              <AdminButton
                                label={'Impersonate'}
                                variant={SiteButtonVariant.Secondary}
                                onClick={handleImpersonate}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Suspense>
  )
}

export default AdminDashboard
