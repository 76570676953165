import { message, Select, Tag } from 'antd'
import { ReactElement, useState } from 'react'
import { PrintAgent } from '../../models/manager/printAgent'
import { EditOutlined } from '@ant-design/icons'
import circleoutlined from '../../assets/images/localPrint/circleoutlined.svg'
import { LoadingSpinner } from '../shared/LoadingSpinner'
import { useAuth0 } from '@auth0/auth0-react'
import { updatePrintAgentName, updateSelectedPrinter } from '../../services/printAgents'
import { errorHandler } from '../../utils/helper'

interface Props {
  printAgent: PrintAgent
}

export function AgentCard({ printAgent }: Props): ReactElement {
  const { getAccessTokenSilently } = useAuth0()
  const [isEditing, setIsEditing] = useState(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [name, setName] = useState(printAgent.Name)
  const [originalName, setOriginalName] = useState<string>(printAgent.Name)
  const [selectedPrinter, setSelectedPrinter] = useState<number | undefined>()
  const [messageApi, contextHolder] = message.useMessage()

  const handleDoubleClick = () => {
    setIsEditing(true)
  }

  const handleIconClick = () => {
    setIsEditing(true)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleSave = async () => {
    if (name === originalName) {
      return setIsEditing(false)
    }
    try {
      setLoading(true)
      const token = await getAccessTokenSilently()
      const payload = { Id: printAgent.Id, Name: name }
      await updatePrintAgentName(payload, token)
      setOriginalName(name)
    } catch (err) {
      errorHandler(err)
    } finally {
      setLoading(false)
      setIsEditing(false)
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSave()
    }
  }

  const handleBlur = (): void => {
    handleSave()
  }

  const handleChangePrinter = (printerId: number) => {
    setSelectedPrinter(printerId)

    getAccessTokenSilently().then(async (token) => {
      await updatePrinterSelected(printerId, token)
    })
  }

  const updatePrinterSelected = async (printerId: number, token: string) => {
    try {
      await updateSelectedPrinter(printAgent?.Id ?? 0, printerId, token)
      messageApi.open({
        type: 'success',
        content: 'Selected printer updated successfully',
        style: { marginTop: '15vh' },
      })
    } catch (error) {
      errorHandler(error)
      messageApi.open({
        type: 'error',
        content: 'Failed to update printer, please try again',
        style: { marginTop: '15vh' },
      })
    }
  }

  return (
    <div className="flex items-center p-4 border-credsPrimaryBlue-100 bg-SecondaryLightBlue-100 border rounded-lg">
      {contextHolder}
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      <div className="flex flex-col w-full gap-2">
        <div className="flex justify-between items-center">
          <Tag color="green" className="flex items-center gap-2">
            <img src={circleoutlined} alt="" />
            Online
            {/* {printAgent.Printers[0].Status} this should be refactored*/}
          </Tag>
          <span className="text-base">ID: {printAgent.Id}</span>
        </div>
        <div className="flex items-center gap-2">
          {isEditing ? (
            <input
              type="text"
              value={name}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              autoFocus
              className="text-base font-semibold"
            />
          ) : (
            <span className="text-base font-semibold" onDoubleClick={handleDoubleClick}>
              {name}
            </span>
          )}
          <EditOutlined className="mt-1 text-TextSecondaryGrey-100 cursor-pointer" onClick={handleIconClick} />
        </div>
        <div className="flex flex-col w-full mt-5">
          <span className="text-xs">Printer selected</span>
          <Select
            onChange={(value) => handleChangePrinter(value)}
            value={selectedPrinter}
            defaultValue={printAgent.Printers.find((printer) => printer.IsSelected === true)?.Id}
            options={printAgent.Printers.map((printer) => ({
              label: printer.Name,
              value: printer.Id,
            }))}
          />
        </div>
        <div className="flex justify-between items-center mt-1">
          {/* TODO: uncomment once showing the print log is a completed feature */}
          <span className="text-credsPrimaryBlue-100 font-light">{/* Print Log */}&nbsp;</span>
          <span className="text-base">{printAgent.Version ? `Version ${printAgent.Version}` : ''}</span>
        </div>
      </div>
    </div>
  )
}
