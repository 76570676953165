import { Job, JobRequest } from '../models/manager/job'
import { apiRequest, printJobFunctionRequest, sendEmailRequest } from '../utils/axios'
import queryString from 'query-string'

interface PrintJobNotificationInternal {
  AccountName: string
  JobId: number
  Environment: string
  AccountGuid: string
  FirstName: string
  LastName: string
  Email: string
  TrackingURL: string
  OrderDate: string
  QtyPersonalized: number
  QtyNotPersonalized: number
  ShippingProvider: string
  CardType: string
  Notches: number
  TrackingNumber: string
}

interface PrintJobNotificationExternal {
  EmailTo: string
  UserOrderName: string
  TrackingURL: string
  JobOrderID: number
  AccountName: string
  OrderDate: string
  Recipient: string
  ShipCompany: string
  ShipAddress: string
  ShipCity: string
  ShipState: string
  ShipCountry: string
  ShipZip: string
  OrderQty: number
  OrderName: string
}

export async function fetchJobs(accountId: number, token: string): Promise<Job[]> {
  return apiRequest('GET', `/jobs/${accountId}`, token)
}

export async function fetchJob(jobId: number, token: string): Promise<Job> {
  return apiRequest('GET', `/job/${jobId}`, token)
}

export async function fetchJobCount(jobId: number, token: string): Promise<number> {
  return apiRequest('GET', `/jobLogCount/${jobId}`, token)
}

export async function saveJob(jobData: JobRequest, token: string): Promise<Job> {
  return apiRequest('POST', '/job', token, jobData)
}

export async function updateJob(jobData: JobRequest, token: string): Promise<void> {
  return apiRequest('PUT', '/job', token, jobData)
}

export async function deleteJob(jobId: number, token: string): Promise<void> {
  return apiRequest('DELETE', `/job/${jobId}`, token)
}

export async function countJobs(accountId: number, token: string): Promise<number> {
  return apiRequest('GET', `/jobCount/${accountId}`, token)
}

export async function createPdfs(jobId: number, token: string): Promise<void> {
  return printJobFunctionRequest('GET', `/createPdfsForJobId?jobId=${jobId}`, token)
}

export async function sendPrintJobNotificationInternal(
  printJobInfo: PrintJobNotificationInternal,
  token: string
): Promise<void> {
  return sendEmailRequest('GET', `/createPrintJobNotificationInternal?${queryString.stringify(printJobInfo)}`, token)
}

export async function sendPrintJobNotificationExternal(
  printJobInfo: PrintJobNotificationExternal,
  token: string
): Promise<void> {
  return sendEmailRequest('GET', `/createPrintJobNotificationExternal?${queryString.stringify(printJobInfo)}`, token)
}

export async function fetchjobLogsCount(jobId: number, token: string): Promise<number> {
  return apiRequest('GET', `/jobLogCount/${jobId}`, token)
}
