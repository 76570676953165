/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactElement, Suspense, useEffect, useState } from 'react'
import { LoadingSpinner } from '../../components/shared/LoadingSpinner'
import { AdminButton, SiteButtonVariant } from '../components/controls/AdminButton'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { Col, Divider, Row, Select, Tag, Typography, message } from 'antd'
import icon_printjob from '../../assets/images/layout/icon_printjob.svg'
import icon_shipping from '../../assets/images/layout/icon_shipping.svg'
import pdf_icon from '../assets/jobs/pdf_icon.svg'
import dayjs from 'dayjs'
import { errorHandler, insertSpaceCamelCase } from '../../utils/helper'
import { STARTER_TEMPLATE_THUMBNAILS_BLOB_FOLDER, Status } from '../../constants/common'
import { CurrencyFormatter } from '../../components/shared/CurrencyFormatter'
import { useAuth0 } from '@auth0/auth0-react'
import { Job } from '../../models/manager/job'
import { Transaction } from '../../models/manager/transaction'
import { Shipment, ShippingTrackRequest, ShippingTrackResponse } from '../../models/manager/shipping'
import { Address } from '../../models/manager/address'
import { getShipmentByJobId, trackShipping } from '../../services/shipping'
import { fetchJobsById, patchJob } from '../services/adminJob'
import { Template } from '../../models/manager/template'
import AdminTemplateCard from '../components/template/AdminTemplateCard'
import { DeliveryTimeLine } from '../../components/shared/DeliveryTimeLine'
import { createPdfs, fetchJobCount } from '../../services/jobs'
import { fetchFolderAsZip } from '../services/adminJob'

const { Text } = Typography

const AdminJobDetail = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const jobId = searchParams.get('jobId')
  const { getAccessTokenSilently } = useAuth0()
  const [currentJob, setCurrentJob] = useState<Job>()
  const [recordSetName, setRecordSetName] = useState('')
  const [templateName, setTemplateName] = useState('')
  const [transactionData, setTransactionData] = useState<Transaction[]>()
  const [quantity, setQuantity] = useState(0)
  const [shipment, setShipment] = useState<Shipment>()
  const [trackShipment, setTrackShipment] = useState<ShippingTrackResponse>()
  const [addressData, setAddressData] = useState<Address>()
  const [jobStatus, setJobStatus] = useState<Status>()
  const [newStatus, setNewStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [accountName, setAccountName] = useState('')
  const [hasJobLogs, setHasJobLogs] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const goToTransactionDetail = () => {
    navigate('/detail-transactions', { state: { jobId: currentJob?.Id, templateId: currentJob?.TemplateId } })
  }

  const isStarterTemplate = (template: Template) => {
    return template.IsLocked && template.IsAvailableToAll
  }

  const statusOptions = Object.entries(Status)
    .filter(([key]) => !parseInt(key)) // Filter out the numeric keys
    .map(([key, value]) => ({ label: key, value }))

  const handleChangeStatus = (value: number) => {
    setJobStatus(value)
    setNewStatus(true)
  }

  const updateStatus = async () => {
    try {
      setIsLoading(true)
      const token = await getAccessTokenSilently()
      await patchJob(
        {
          Id: parseInt(jobId ?? '0'),
          StatusId: jobStatus ?? 1,
        },
        token
      )
      messageApi.open({
        type: 'success',
        content: 'Print Job Status successfully Changed',
        style: { marginTop: '15vh' },
      })
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      messageApi.open({
        type: 'error',
        content: 'Could not update the Job status, please try again',
        style: { marginTop: '15vh' },
      })
    }
    setNewStatus(false)
  }

  const handleRegeneratePdf = async () => {
    try {
      const btnGenerate = document.getElementById('btnRegenerate')
      btnGenerate?.setAttribute('disabled', 'true')
      messageApi.open({
        type: 'info',
        content: 'PDF regeneration triggered successfully. Please wait for the job to complete',
        style: { marginTop: '15vh' },
      })
      await createPdfs(currentJob?.Id ?? 0, await getAccessTokenSilently())
      btnGenerate?.removeAttribute('disabled')
    } catch (error) {
      errorHandler(error)
    }
  }

  const handleClose = () => {
    navigate(-1)
  }

  useEffect(() => {
    if (jobId) {
      if (location.state?.accountName) setAccountName(location.state?.accountName)
      getAccessTokenSilently().then((token) => {
        getShipmentByJobId(parseInt(jobId || '0'), token).then(setShipment)
        fetchJobsById(parseInt(jobId), token).then((data) => {
          setCurrentJob(data)
          setJobStatus(data.StatusId)
          setRecordSetName(data.Template?.RecordSet?.Name ?? '')
          setTemplateName(data.Template?.Name ?? '')
          setTransactionData(data.Transactions)
          setQuantity(data.Quantity ?? 0)
          setAddressData(data.Address)
        })
        fetchJobCount(parseInt(jobId), token).then((data) => {
          setHasJobLogs(data > 0)
        })
      })
    } else {
      handleClose()
    }
  }, [])

  useEffect(() => {
    if (currentJob && currentJob.StatusId != Status.InProgress && shipment) {
      const trackShipmentRequest: ShippingTrackRequest = {
        TrackingNumber: shipment?.TrackingNumber ?? '',
        Carrier: shipment?.Provider ?? '',
        // For Testing only:
        // TrackingNumber: 'SHIPPO_TRANSIT',
        // TrackingNumber: 'SHIPPO_DELIVERED',
        // Carrier: 'shippo',
      }
      getAccessTokenSilently().then((token) => {
        trackShipping(trackShipmentRequest, token).then(setTrackShipment)
      })
    }
  }, [shipment, currentJob])

  async function downloadAllPdfs() {
    setIsLoading(true)
    await fetchFolderAsZip(
      `${currentJob?.Account?.AccountGuid ?? ''}/print-jobs/${currentJob?.Id?.toString() ?? ''}`,
      await getAccessTokenSilently()
    )
      .then(async (response) => {
        const blob = new Blob([response], { type: 'application/zip' })
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `PrintJob_${currentJob?.Id}.zip`
        a.click()
      })
      .catch((error) => {
        errorHandler(error)
      })
    setIsLoading(false)
  }

  return (
    <Suspense fallback={<LoadingSpinner isLoading={true} label="Loading..." />}>
      {isLoading && <LoadingSpinner isLoading={isLoading} label="Loading..." />}
      {contextHolder}
      <div className="container mx-auto">
        <div className="flex justify-center items-center bg-SecondaryBgWhite-100 rounded-lg px-6 my-2 py-2">
          <h1 className="text-xl font-semibold text-PrimaryDarkBlue-100 flex-1">Admin Print Jobs</h1>
          <AdminButton label={'Back'} onClick={handleClose} />
        </div>
        <div className="bg-SecondaryBgWhite-100 shadow-md rounded-lg px-6 py-4 h-full">
          <Row gutter={[24, 24]}>
            <Col xs={24} lg={24}>
              <div className="border border-BorderLightGrey-100 rounded-md pt-4 h-full px-12">
                <div className="flex items-start gap-2 px-6 pt-2">
                  <img className="" src={icon_printjob} alt="Print Job" />
                  <span className="font-bold text-lg">Print Job</span>
                </div>
                <Divider className="border-BorderLightGrey-100 mb-0" />
                <div className="flex flex-col xl:flex-row justify-start items-center gap-2 xl:gap-4 px-4 py-4">
                  <div className="flex justify-start flex-col items-start gap-2">
                    <Text type="secondary">Last Update</Text>
                    <Text>{dayjs(currentJob?.UpdatedAt).format('MMM D, h:mm A')}</Text>
                  </div>
                  <Divider
                    type="vertical"
                    className="border-BorderLightGrey-100 hidden xl:block"
                    style={{ height: '4.0625rem' }}
                  />
                  <div className="flex flex-col items-start gap-2">
                    <Text type="secondary">Status</Text>
                    <div className="flex justify-center items-center gap-5 w-full">
                      {currentJob?.StatusId && (
                        <Select
                          className="w-32"
                          value={jobStatus}
                          onChange={handleChangeStatus}
                          options={statusOptions.map((option) => ({
                            value: option.value,
                            label: `${insertSpaceCamelCase(option.label)}`,
                          }))}
                        />
                      )}
                      {newStatus && <AdminButton label={'Save'} onClick={updateStatus} />}
                    </div>
                  </div>
                  <Divider
                    type="vertical"
                    className="border-BorderLightGrey-100 hidden xl:block"
                    style={{ height: '4.0625rem' }}
                  />
                  <div className="flex flex-col items-start gap-2">
                    <Text type="secondary">Job ID</Text>
                    <Text>{currentJob?.Id}</Text>
                  </div>
                </div>
                <Divider className="border-BorderLightGrey-100 m-0" />
                <div className="flex items-center justify-around w-full">
                  <div className="flex w-full flex-col justify-start items-center gap-2 py-2 my-2 px-2">
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <Text className="sm:w-[20%]" type="secondary">
                        Date Created
                      </Text>
                      <Text className="w-auto sm:w-[40%]">{dayjs(currentJob?.CreatedAt).format('MMMM D, YYYY')}</Text>
                    </div>
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <Text className="sm:w-[20%]" type="secondary">
                        Job Name
                      </Text>
                      <Text className="w-auto sm:w-[40%]">{currentJob?.Name}</Text>
                    </div>
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <Text className="sm:w-[20%]" type="secondary">
                        Media Type
                      </Text>
                      <Text className="w-auto sm:w-[40%]">
                        <Tag color="success">{currentJob?.Template?.TemplateType?.Name ?? ''}</Tag>
                      </Text>
                    </div>
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <Text className="sm:w-[20%]" type="secondary">
                        Template
                      </Text>
                      <Text className="w-auto sm:w-[40%]">{templateName}</Text>
                    </div>
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <Text className="sm:w-[20%]" type="secondary">
                        List Name
                      </Text>
                      <Link
                        className="w-auto sm:w-[40%] text-credsPrimaryBlue-100 underline hover:!underline"
                        to={`/admin/job-log-listing?jobId=${currentJob?.Id}&recordSetId=${currentJob?.Template?.RecordSetId}&userAccountUid=${currentJob?.Account?.AccountGuid}`}
                      >
                        {recordSetName}
                      </Link>
                    </div>
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <Text className="sm:w-[20%]" type="secondary">
                        Quantity:
                      </Text>
                      <Text className="w-auto sm:w-[40%]">{quantity}</Text>
                    </div>
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <Text className="sm:w-[20%]" type="secondary">
                        Amount:
                      </Text>
                      <Text className="w-auto sm:w-[40%]">
                        <CurrencyFormatter
                          amount={Math.abs(transactionData?.reduce((sum, record) => sum + record.Amount, 0) || 0)}
                        />
                      </Text>
                    </div>
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <Text className="sm:w-[20%]" type="secondary">
                        Account Name:
                      </Text>
                      <Text className="w-auto sm:w-[40%]">{accountName}</Text>
                    </div>
                    <div className="w-full flex justify-start  items-center flex-col sm:flex sm:flex-row gap-2 flex-wrap">
                      <AdminButton
                        label="Order Details"
                        style={{ margin: 0 }}
                        variant={SiteButtonVariant.Secondary}
                        onClick={goToTransactionDetail}
                      />
                    </div>
                  </div>
                  <div className="flex py-2">
                    {currentJob?.Template && (
                      <AdminTemplateCard
                        style={{ width: 250 }}
                        template={currentJob?.Template}
                        thumbnailLocation={
                          isStarterTemplate(currentJob?.Template)
                            ? STARTER_TEMPLATE_THUMBNAILS_BLOB_FOLDER
                            : currentJob?.Account?.AccountGuid ?? ''
                        }
                        actions={false}
                      />
                    )}
                  </div>
                </div>
                <Divider className="border-BorderLightGrey-100 mb-0" />
                <div className="flex items-center">
                  <div className="flex items-center gap-2 w-full my-4">
                    <img src={pdf_icon} alt="" />
                    <span className="text-lg font-semibold">Print Job</span>
                    <span className="cursor-pointer text-credsPrimaryBlue-100 underline" onClick={downloadAllPdfs}>
                      Select to Download all Printed Credentials
                    </span>
                  </div>
                  {hasJobLogs && (
                    <AdminButton id="btnRegenerate" label={'Regenerate Pdfs'} onClick={handleRegeneratePdf} />
                  )}
                </div>
              </div>
            </Col>
            <Col xs={24} lg={24}>
              <div className="border border-BorderLightGrey-100 rounded-md pt-4 h-full px-12">
                <div className="flex items-center gap-2 px-6 pt-2">
                  <img className="" src={icon_shipping} alt="Shipping" />
                  <span className="font-bold text-lg">Shipping</span>
                </div>
                <Divider className="border-BorderLightGrey-100 mb-0" />
                <div className="flex flex-col xl:flex-row justify-start items-center gap-2 px-2 py-4">
                  <div className="flex flex-col items-center gap-2">
                    <Text type="secondary">Tracking No</Text>
                    <Text>
                      <Link to={shipment?.TrackingUrl ?? ''} target="_blank" rel="noopener noreferrer">
                        {shipment?.TrackingNumber}
                      </Link>
                    </Text>
                  </div>
                  <Divider
                    type="vertical"
                    className="border-BorderLightGrey-100 hidden xl:block"
                    style={{ height: '4.0625rem' }}
                  />
                  <div className="flex flex-col items-center gap-2">
                    <Text type="secondary">Shipping Label</Text>
                    <Text className="text-sm text-center">
                      <Link to={shipment?.LabelUrl ?? ''} target="_blank" rel="noopener noreferrer">
                        Get Shipping Label Here
                      </Link>
                    </Text>
                  </div>
                  <Divider
                    type="vertical"
                    className="border-BorderLightGrey-100 hidden xl:block"
                    style={{ height: '4.0625rem' }}
                  />
                  <div className="flex flex-col items-center gap-2">
                    <Text type="secondary">Method</Text>
                    <Text>{shipment?.Provider}</Text>
                  </div>
                  <Divider
                    type="vertical"
                    className="border-BorderLightGrey-100 hidden xl:block"
                    style={{ height: '4.0625rem' }}
                  />
                  <div className="flex flex-col items-center gap-2">
                    <Text type="secondary">Date Shipped</Text>
                    <Text>{dayjs(currentJob?.CreatedAt).format('MMMM D, YYYY')}</Text>
                  </div>
                  <Divider
                    type="vertical"
                    className="border-BorderLightGrey-100 hidden xl:block"
                    style={{ height: '4.0625rem' }}
                  />
                  <div className="flex flex-col items-center gap-2">
                    <Text type="secondary">Service Level</Text>
                    <Text>{shipment?.ServiceLevel ?? '---'}</Text>
                  </div>
                  <Divider
                    type="vertical"
                    className="border-BorderLightGrey-100 hidden xl:block"
                    style={{ height: '4.0625rem' }}
                  />
                  <div className="flex flex-col items-center gap-2">
                    <Text type="secondary">Estimated Days</Text>
                    <Text>{shipment?.EstimatedDays ?? '---'}</Text>
                  </div>
                </div>
                <Divider className="border-BorderLightGrey-100 m-0" />

                <div className="my-5 flex flex-col gap-2 w-full xl:flex-row">
                  <div className="bg-white p-4 w-full">
                    <h3 className="text-lg font-semibold mb-2">Delivery Address:</h3>
                    <p className="text-sm">
                      {addressData?.FullName && <span className="block font-medium">{addressData.FullName}</span>}
                      {addressData?.Company && <span className="block">{addressData.Company}</span>}
                      <span className="block">{addressData?.Address1}</span>
                      {addressData?.Address2 && <span className="block">{addressData.Address2}</span>}
                      {addressData?.Address3 && <span className="block">{addressData.Address3}</span>}
                      <span className="block">
                        {addressData?.City}, {addressData?.Region?.Iso2}
                      </span>
                      {addressData?.ZipPostal && <span className="block">{addressData.ZipPostal}</span>}
                      {addressData?.Region?.Country?.Iso3 && (
                        <span className="block">{addressData.Region.Country.Iso3}</span>
                      )}
                      {addressData?.PhoneNumber && <span className="block">{addressData.PhoneNumber}</span>}
                    </p>
                  </div>
                  <DeliveryTimeLine currentJob={currentJob} trackShipment={trackShipment} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Suspense>
  )
}

export default AdminJobDetail
